<template>
  <div class="index">
    <p class="containerTitle">讣告</p>
    <div class="jngBox">
      <!-- <div class="jngBoxItem" v-for="(item,index) in 2" :key="index"></div> -->
      <div class="jngBoxItem">
        <img src="@/assets/images/image.png" alt="" class="jngBoxItemNull" />
        <div class="jngBoxItemRight">暂无讣告，快去创建吧</div>
      </div>
    </div>
    <div class="containerTitleFooter">
      <div class="containerTitleFooterBtn" +>创建新的讣告</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.index {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  margin-top: 10px;
   .containerTitle {
          padding-left: 16px;
          height: 33px;
          font-size: 24px;
          font-weight: 500;
          color: #1a1a1a;
          line-height: 33px;
          letter-spacing: 1px;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span:nth-child(2) {
            font-size: 16px;
            font-weight: 400;
            color: #838383;
            letter-spacing: 1px;
            margin-top: 4px;
            i {
              margin-left: 4px;
            }
          }
        }
        .containerTitle::before {
          content: "";
          display: inline;
          position: absolute;
          top: 5px;
          left: 0px;
          width: 4px;
          height: 24px;
          background: #299654;
          border-radius: 2px;
        }
        .jngBox {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .jngBoxItem {
            width: 380px;
            height: 124px;
            background: #ffffff;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 12px 20px;
            margin-top: 16px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .jngBoxItemNull {
              width: 100px;
              height: 100px;
              border-radius: 2px;
              border: 2px solid #979797;
            }
            .jngBoxItemRight {
              margin-left: 24px;
              display: flex;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              color: #b0b0b0;
              letter-spacing: 1px;
            }
          }
          .jngBoxItem:nth-child(2n) {
            margin-left: 20px;
          }
        }
        .qftB {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
        .qftBBox {
          width: 800px;
          height: 40px;
          background: #ffffff;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          margin-left: 10px;
          p:nth-child(1) {
            width: 529px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
            line-height: 20px;
            letter-spacing: 1px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          p:nth-child(2) {
            width: 80px;
            height: 14px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #b0b0b0;
            line-height: 14px;
          }
        }
        .qftBBox:nth-child(1) {
          margin-top: 16px;
        }
        .qftBBox:not(:nth-child(1)) {
          margin-top: 14px;
        }
        .containerTitleFooter {
          display: flex;
          flex-wrap: nowrap;
          .containerTitleFooterBtn {
            width: 380px;
            height: 36px;
            background: linear-gradient(
              90deg,
              #40bf87 0%,
              #19a4bd 47%,
              #208eff 100%
            );
            border-radius: 4px;
            line-height: 36px;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;
            letter-spacing: 1px;
            margin-top: 8px;
          }
        }
        .gmyB {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
        .gmyBBox:nth-child(2n) {
          margin-left: 20px;
        }
        .gmyBBox {
          margin-top: 16px;
          width: 380px;
          height: 204px;
          background: #ffffff;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          padding: 20px;
        }
        .gmyBBoxTop {
          display: flex;
          flex-wrap: nowrap;
          img {
            width: 100px;
            height: 100px;
            border-radius: 2px;
            border: 2px solid #979797;
          }
          .gmyBBoxTopRig {
            margin-left: 16px;
            .gmyBBoxTopRigOne {
              font-size: 14px;
              font-weight: 500;
              color: #1a1a1a;
              letter-spacing: 1px;
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              .fri {
                width: 30px;
                height: 14px;
                background: linear-gradient(180deg, #d7d7d7 0%, #a9a9a9 100%);
                border-radius: 2px;
                line-height: 14px;
                text-align: center;
                font-size: 10px;
                font-weight: 400;
                color: #ffffff;
                margin-left: 10px;
              }
              .friO {
                width: 30px;
                height: 14px;
                background: linear-gradient(180deg, #299654 0%, #013616 100%);
                border-radius: 2px;
                line-height: 14px;
                text-align: center;
                font-size: 10px;
                font-weight: 400;
                color: #ffffff;
                margin-left: 10px;
              }
            }
            .gmyBBoxTopRigTwo {
              height: 17px;
              font-size: 12px;
              font-weight: 400;
              color: #1a1a1a;
              line-height: 17px;
              margin-top: 4px;
            }
            .gmyBBoxTopRigThree {
              width: 228px;
              height: 34px;
              font-size: 12px;
              font-weight: 400;
              color: #7f7f7f;
              line-height: 17px;
              margin-top: 8px;
              display: -webkit-box;
              overflow: hidden;
              white-space: normal !important;
              text-overflow: ellipsis;
              word-wrap: break-word;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .gmyBBoxTopRigFour {
              width: 228px;
              display: flex;
              justify-content: flex-end;
              text-align: right;
              margin-top: 3px;
              p {
                height: 14px;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(176, 176, 176, 1);
                line-height: 14px;
                span {
                  color: #299654;
                }
              }
            }
          }
        }
        .gmyBBoxFooter {
          display: flex;
          justify-content: space-evenly;
          margin-top: 16px;
          img {
            width: 48px;
            height: 48px;
          }
        }
}
</style>
