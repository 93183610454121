<template>
  <div class="sacrifice">
    <img src="@/assets/images/banner.png" alt="" class="aboutBan" />
    <div class="aboutTxt">韬儒-在线祭祀</div>
    <div class="aboutBox">
      <div class="aboutBoxMain">
        <div class="aboutBoxMainLeft">
          <div class="aboutBoxMainLeftTop" @click="sarClick">在线祭祀</div>
          <div
            class="aboutBoxMainLeftmain mainstyle"
            v-for="(item, index) in aboutBackList"
            :key="index"
            @click="aboutBackClick(index)"
          >
            <img
              v-if="aboutBackNum == index"
              src="@/assets/images/aboutBack.png"
              class="aboutBack"
            />
            <img
              v-else
              src="@/assets/images/aboutBack1.png"
              class="aboutBack"
            />
            <!-- <img :src="(aboutBackNum == index) ? '@/assets/images/aboutBack.png':'@/assets/images/aboutBack1.png'" class="aboutBack"> -->
            <p
              class="mainstyle"
              :style="
                aboutBackNum == index && 'font-weight: 600;color: #299654;'
              "
            >
              {{ item }}
            </p>
          </div>
          <p class="zxPhone">
            <icon class="iconfont icondianhua"></icon>咨询热线
          </p>
          <p class="zxPhoneNum">400-119-0811</p>
        </div>
        <div class="aboutBoxMainRight" v-if="aboutBackNum == null">
          <div class="aboutBoxMainRightBox" v-if="true">
            <div class="container">
              <p class="containerTitle"><span>纪念馆</span></p>
              <div class="jngBox">
                <!-- <div class="jngBoxItem" v-for="(item,index) in 2" :key="index"></div> -->
                <div class="jngBoxItem">
                  <img
                    src="@/assets/images/image.png"
                    alt=""
                    class="jngBoxItemNull"
                  />
                  <div class="jngBoxItemRight">暂无纪念馆，快去创建吧</div>
                </div>
              </div>
              <div class="containerTitleFooter">
                <div class="containerTitleFooterBtn">创建新的纪念馆</div>
              </div>
              <p class="containerTitle" style="margin-top: 24px">
                <span>公墓园</span>
                <span>查看更多<i class="iconfont iconxiangyou1"></i></span>
              </p>
              <div class="gmyB">
                <div class="gmyBBox" v-for="(item, index) in 4" :key="index">
                  <div class="gmyBBoxTop">
                    <img src="@/assets/images/image.png" alt="" />
                    <div class="gmyBBoxTopRig">
                      <div class="gmyBBoxTopRigOne">
                        <span>毛泽东</span>
                        <div class="fri">私密</div>
                        <div class="friO">私密</div>
                      </div>
                      <div class="gmyBBoxTopRigTwo">1893.12.26~1976.0909</div>
                      <div class="gmyBBoxTopRigThree">
                        泽东担任中华人民共和国最高领导人。他对马克思列宁主义的发展大城市的…
                      </div>
                      <div class="gmyBBoxTopRigFour">
                        <p><span>125人</span>祭拜过</p>
                      </div>
                    </div>
                  </div>
                  <div class="gmyBBoxFooter">
                    <img
                      v-for="(item, index) in iconList"
                      :src="item.src"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <p class="containerTitle" style="margin-top: 24px">
                <span>祈福堂</span>
                <span>查看更多<i class="iconfont iconxiangyou1"></i></span>
              </p>
              <div class="qftBBox" v-for="(item, index) in 4" :key="index">
                <p>
                  泽东担任中华人民共和国最高领形成速度快拉扯出现在成都sack劳动生产率大…
                </p>
                <p>2020-10-15</p>
              </div>
            </div>
          </div>
          <div v-else>
            <router-view />
          </div>
        </div>
        <div class="aboutBoxMainRight" v-if="aboutBackNum == 0">
          <div class="aboutBoxMainRightBox" v-if="true">
            <div class="container">
              <p class="containerTitle"><span>我的纪念馆</span></p>
              <div class="jngBox">
                <!-- <div class="jngBoxItem" v-for="(item,index) in 2" :key="index"></div> -->
                <div class="jngBoxItem">
                  <img
                    src="@/assets/images/image.png"
                    alt=""
                    class="jngBoxItemNull"
                  />
                  <div class="jngBoxItemRight">暂无纪念馆，快去创建吧</div>
                </div>
              </div>
              <div class="containerTitleFooter">
                <div class="containerTitleFooterBtn">创建新的纪念馆</div>
              </div>
            </div>
          </div>
          <div v-else>
            <router-view />
          </div>
        </div>
        <div class="aboutBoxMainRight" v-if="aboutBackNum == 1">
          <div class="aboutBoxMainRightBox" v-if="true">
            <div class="container">
              <p class="containerTitle"><span>祠堂</span></p>
              <div class="jngBox">
                <!-- <div class="jngBoxItem" v-for="(item,index) in 2" :key="index"></div> -->
                <div class="jngBoxItem">
                  <img
                    src="@/assets/images/image.png"
                    alt=""
                    class="jngBoxItemNull"
                  />
                  <div class="jngBoxItemRight">暂无祠堂，快去创建吧</div>
                </div>
              </div>
              <div class="containerTitleFooter">
                <div class="containerTitleFooterBtn">创建新的祠堂</div>
              </div>
            </div>
          </div>
          <div v-else>
            <router-view />
          </div>
        </div>
        <div class="aboutBoxMainRight" v-if="aboutBackNum == 2">
          <div class="aboutBoxMainRightBox">
            <div class="container">
              <div v-if="true">
                <obituariesIndex />
              </div>
              <div v-else>
                <router-view />
              </div>
            </div>
          </div>
        </div>
        <div class="aboutBoxMainRight" v-if="aboutBackNum == 3">
          <div class="container">
            <p class="containerTitle">公墓园</p>
            <div class="gmyB">
              <div class="gmyBBox" v-for="(item, index) in 4" :key="index">
                <div class="gmyBBoxTop">
                  <img src="@/assets/images/image.png" alt="" />
                  <div class="gmyBBoxTopRig">
                    <div class="gmyBBoxTopRigOne">
                      <span>毛泽东</span>
                      <div class="fri">私密</div>
                      <div class="friO">私密</div>
                    </div>
                    <div class="gmyBBoxTopRigTwo">1893.12.26~1976.0909</div>
                    <div class="gmyBBoxTopRigThree">
                      泽东担任中华人民共和国最高领导人。他对马克思列宁主义的发展大城市的…
                    </div>
                    <div class="gmyBBoxTopRigFour">
                      <p><span>125人</span>祭拜过</p>
                    </div>
                  </div>
                </div>
                <div class="gmyBBoxFooter">
                  <img
                    v-for="(item, index) in iconList"
                    :src="item.src"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="aboutBoxMainRight" v-if="aboutBackNum == 4">
          <div class="container">
            <p class="containerTitle">祈福堂</p>
            <div class="qftB">
              <div class="qftBBox" v-for="(item, index) in 4" :key="index">
                <p>
                  泽东担任中华人民共和国最高领形成速度快拉扯出现在成都sack劳动生产率大…
                </p>
                <p>2020-10-15</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import obituariesIndex from "./obituaries/index";
export default {
  name: "sacrifice",
  components: { obituariesIndex },
  data() {
    return {
      aboutBackNum: null,
      aboutBackList: ["纪念馆", "祠堂", "讣告", "公墓园", "祈福堂"],
      iconList: [
        { src: require("@/assets/images/iconOne.png") },
        { src: require("@/assets/images/iconTwo.png") },
        { src: require("@/assets/images/iconThree.png") },
        { src: require("@/assets/images/iconFour.png") },
      ],
    };
  },
  methods: {
    aboutBackClick(e) {
      this.aboutBackNum = e;
    },
    sarClick() {
      this.aboutBackNum = null;
    },
  },
};
</script>
<style lang="less" scoped>
.sacrifice {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  position: relative;
  .aboutTxt {
    width: 270px;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 50px;
    letter-spacing: 2px;
    position: absolute;
    top: 75px;
    left: 19.5%;
  }
  .aboutBan {
    width: 100%;
    height: 280px;
    background: rgba(0, 0, 0, 0.2);
  }
  .aboutBox {
    width: 1120px;
    margin: -40px auto 40px;
    .aboutBoxMain {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .aboutBoxMainLeft {
        width: 220px;
        height: 580px;
        background: #ffffff;
        border-radius: 2px;
        padding-bottom: 25px;
        .aboutBoxMainLeftTop {
          width: 220px;
          height: 40px;
          background: #299654;
          border-radius: 2px 2px 0px 0px;
          text-align: center;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          letter-spacing: 1px;
          cursor: pointer;
        }
        .aboutBack {
          width: 117px;
          height: 28px;
          position: absolute;
        }
        .mainstyle {
          text-align: center;
          height: 25px;
          font-size: 18px;
          font-weight: 500;
          color: #299654;
          line-height: 25px;
          letter-spacing: 1px;
          color: #1a1a1a;
          cursor: pointer;
        }
        .aboutBoxMainLeftmain {
          box-sizing: content-box;
          padding: 28px 52px;
          border-bottom: 1px solid rgba(192, 192, 192, 0.4);
        }
        .zxPhone {
          .icondianhua {
            font-size: 26px;
            margin-right: 4px;
          }
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #c0c0c0;
          line-height: 25px;
          letter-spacing: 1px;
          text-align: center;
          margin-top: 40px;
        }
        .zxPhoneNum {
          height: 29px;
          font-size: 24px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #299654;
          line-height: 29px;
          letter-spacing: 1px;
          text-align: center;
          margin-top: 8px;
        }
      }
      .container {
        // border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        padding: 24px;
        background: #ffffff;
        // height: 855px;
        width: 100%;
        // height:100%;
        .containerTitle {
          padding-left: 16px;
          height: 33px;
          font-size: 24px;
          font-weight: 500;
          color: #1a1a1a;
          line-height: 33px;
          letter-spacing: 1px;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span:nth-child(2) {
            font-size: 16px;
            font-weight: 400;
            color: #838383;
            letter-spacing: 1px;
            margin-top: 4px;
            i {
              margin-left: 4px;
            }
          }
        }
        .containerTitle::before {
          content: "";
          display: inline;
          position: absolute;
          top: 5px;
          left: 0px;
          width: 4px;
          height: 24px;
          background: #299654;
          border-radius: 2px;
        }
        .jngBox {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .jngBoxItem {
            width: 380px;
            height: 124px;
            background: #ffffff;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 12px 20px;
            margin-top: 16px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .jngBoxItemNull {
              width: 100px;
              height: 100px;
              border-radius: 2px;
              border: 2px solid #979797;
            }
            .jngBoxItemRight {
              margin-left: 24px;
              display: flex;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              color: #b0b0b0;
              letter-spacing: 1px;
            }
          }
          .jngBoxItem:nth-child(2n) {
            margin-left: 20px;
          }
        }
        .qftB {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
        .qftBBox {
          width: 800px;
          height: 40px;
          background: #ffffff;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          margin-left: 10px;
          p:nth-child(1) {
            width: 529px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
            line-height: 20px;
            letter-spacing: 1px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          p:nth-child(2) {
            width: 80px;
            height: 14px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #b0b0b0;
            line-height: 14px;
          }
        }
        .qftBBox:nth-child(1) {
          margin-top: 16px;
        }
        .qftBBox:not(:nth-child(1)) {
          margin-top: 14px;
        }
        .containerTitleFooter {
          display: flex;
          flex-wrap: nowrap;
          .containerTitleFooterBtn {
            width: 380px;
            height: 36px;
            background: linear-gradient(
              90deg,
              #40bf87 0%,
              #19a4bd 47%,
              #208eff 100%
            );
            border-radius: 4px;
            line-height: 36px;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;
            letter-spacing: 1px;
            margin-top: 8px;
          }
        }
        .gmyB {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
        .gmyBBox:nth-child(2n) {
          margin-left: 20px;
        }
        .gmyBBox {
          margin-top: 16px;
          width: 380px;
          height: 204px;
          background: #ffffff;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          padding: 20px;
        }
        .gmyBBoxTop {
          display: flex;
          flex-wrap: nowrap;
          img {
            width: 100px;
            height: 100px;
            border-radius: 2px;
            border: 2px solid #979797;
          }
          .gmyBBoxTopRig {
            margin-left: 16px;
            .gmyBBoxTopRigOne {
              font-size: 14px;
              font-weight: 500;
              color: #1a1a1a;
              letter-spacing: 1px;
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              .fri {
                width: 30px;
                height: 14px;
                background: linear-gradient(180deg, #d7d7d7 0%, #a9a9a9 100%);
                border-radius: 2px;
                line-height: 14px;
                text-align: center;
                font-size: 10px;
                font-weight: 400;
                color: #ffffff;
                margin-left: 10px;
              }
              .friO {
                width: 30px;
                height: 14px;
                background: linear-gradient(180deg, #299654 0%, #013616 100%);
                border-radius: 2px;
                line-height: 14px;
                text-align: center;
                font-size: 10px;
                font-weight: 400;
                color: #ffffff;
                margin-left: 10px;
              }
            }
            .gmyBBoxTopRigTwo {
              height: 17px;
              font-size: 12px;
              font-weight: 400;
              color: #1a1a1a;
              line-height: 17px;
              margin-top: 4px;
            }
            .gmyBBoxTopRigThree {
              width: 228px;
              height: 34px;
              font-size: 12px;
              font-weight: 400;
              color: #7f7f7f;
              line-height: 17px;
              margin-top: 8px;
              display: -webkit-box;
              overflow: hidden;
              white-space: normal !important;
              text-overflow: ellipsis;
              word-wrap: break-word;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .gmyBBoxTopRigFour {
              width: 228px;
              display: flex;
              justify-content: flex-end;
              text-align: right;
              margin-top: 3px;
              p {
                height: 14px;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(176, 176, 176, 1);
                line-height: 14px;
                span {
                  color: #299654;
                }
              }
            }
          }
        }
        .gmyBBoxFooter {
          display: flex;
          justify-content: space-evenly;
          margin-top: 16px;
          img {
            width: 48px;
            height: 48px;
          }
        }
      }
      .aboutBoxMainRight {
        width: 860px;
        background: #ffffff;
        border-radius: 2px;
        .aboutBoxMainRightBox {
          width: 100%;
        }
      }
    }
  }
}
</style>
